<template>
  <div class="deposit-container">
    <h2>Deposit Funds</h2>
    
    <form @submit.prevent="handleDeposit">
      <div class="form-row">
        <div class="form-group">
          <label for="currency">Select Currency:</label>
          <select v-model="currency" id="currency" required>
            <option value="USDT">Tether (USDT)</option>
            <option value="BTC">Bitcoin (BTC)</option>
            <option value="ETH">Ethereum (ETH)</option>
            <option value="USDC">USD Coin (USDC)</option>
            <option value="TRX">TRON (TRX)</option>
            <option value="BNB">BNB (BNB)</option>
            <option value="DOGE">Dogecoin (DOGE)</option>
            <option value="LTC">Litecoin (LTC)</option>
            <option value="DGB">DigiByte (DGB)</option>
            <option value="XMR">Monero (XMR)</option>
            <option value="TON">Toncoin (TON)</option>
            <option value="MATIC">Polygon (MATIC)</option>
            <option value="BCH">Bitcoin Cash (BCH)</option>
            <option value="SHIB">Shiba Inu (SHIB)</option>
            <option value="SOL">Solana (SOL)</option>
            <option value="NOT">NotCoin (NOT)</option>
          </select>
        </div>
        
        <div class="form-group amount-group">
          <label for="amount" style="margin-left: 10px">Amount:</label>
          <input style="margin-left: 10px" type="number" v-model="amount" id="amount" required min="0.01" step="0.01">
        </div>
      </div>

      <button type="submit">Deposit</button>
    </form>

    <div v-if="payLink" class="payment-iframe">
      <iframe :src="payLink" frameborder="0" width="100%" height="500px"></iframe>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';

export default {
  data() {
    return {
      currency: 'USDT',
      amount: 0,
      payLink: ''
    };
  },
  methods: {
    async handleDeposit() {
      try {
        const response = await axios.post('/api/create-payment', {
          amount: this.amount,
          currency: this.currency,
          userId: this.authUser.user._id
        });

        if (response.data.payLink) {
          this.payLink = response.data.payLink;
        } else {
          alert('Error creating deposit request.');
        }
      } catch (error) {
        console.error('Deposit Error:', error);
        alert('There was an error processing your request.');
      }
    }
  },
  computed: {
    ...mapGetters(['authUser'])
  }
};
</script>

<style scoped>
.deposit-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  color: #ff9400;
}

h2 {
  margin: 0;
  padding: 0;
}

.form-row {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}

.form-group {
  flex: 1;
}

.amount-group {
  margin-left: 20px;
  flex: 1;
}

label {
  display: block;
  margin-bottom: 5px;
}

input, select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: calc(100% - 16px);
}

button {
  padding: 15px 30px;
  background: linear-gradient(255deg, #ff9400 -25%, #aa5c00 100%);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  display: block;
  width: 200px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

button:hover {
  background: linear-gradient(255deg, #ff9400 -25%, #aa5c00 100%);
}

.payment-iframe {
  margin-top: 20px;
  width: 100%;
  max-width: 800px; /* İhtiyacınıza göre genişliği ayarlayabilirsiniz */
  margin-left: auto;
  margin-right: auto;
}

h3 {
  margin-top: 20px;
}
</style>
