<template>
    <div class="home">
        <div class="home-banner">
            <HomeBannerUser />
        </div>
        
        <!-- Butonlar -->
        <div class="home-buttons">
            <div class="button-item" v-for="(button, index) in filteredButtons" :key="index">
                <img :src="button.imgSrc" alt="Button Image">
                <span>{{ button.text }}</span>
            </div>
        </div>

        <!-- Kategoriler -->
        <div v-for="(category, index) in categories" :key="index" class="category-section">
            <div class="category-header">
                <div class="category-title">
                    <img :src="category.icon" alt="Category Icon" class="category-icon">
                    <h2>{{ category.name }}</h2>
                </div>
                <router-link :to="`/category/${category.route}`" class="see-all-button">
                    See All {{ category.games.length }}
                </router-link>
            </div>
         
        <div class="games-list">
                <div v-for="(game, idx) in category.games.slice(0, 18)" :key="idx" class="game-item">
                    <a :href="game.href">
                        <img :src="game.banner" alt="Game Banner" class="game-banner">
                        <span class="game-name">{{ game.game_name }}</span>
                    </a>
                </div>
                
            </div>
            
        </div>

        <HomeDepositMethods />
        <Bets />
    </div>
</template>

<script>
import axios from 'axios';
import Bets from '@/components/bets/Bets';
import HomeBannerUser from '@/components/home/HomeBannerUser';
import HomeDepositMethods from '@/components/home/HomeDepositMethods';
import { mapGetters } from 'vuex';

export default {
    name: 'Home',
    metaInfo: {
        title: 'RivoBIT: The Innovative Crypto Gambling Site'
    },
    components: {
        Bets,
        HomeBannerUser,
        HomeDepositMethods
    },
    data() {
        return {
            searchQuery: '', // Arama alanı için data
            buttons: [
                { imgSrc: 'https://banner.rivobit.com/category/hot.png', text: 'Hot' },
                { imgSrc: 'https://banner.rivobit.com/category/case.png', text: 'Case' },
                { imgSrc: 'https://banner.rivobit.com/category/bj.png', text: 'BJ' },
                { imgSrc: 'https://banner.rivobit.com/category/dice.png', text: 'Dice' },
                { imgSrc: 'https://banner.rivobit.com/category/crash.png', text: 'Crash' },
                { imgSrc: 'https://banner.rivobit.com/category/mine.png', text: 'Mine' },
                { imgSrc: 'https://banner.rivobit.com/category/btl.png', text: 'Battle' },
                { imgSrc: 'https://banner.rivobit.com/category/roll.png', text: 'Roll' },
                { imgSrc: 'https://banner.rivobit.com/category/rlt.png', text: 'Roulette' },
                { imgSrc: 'https://banner.rivobit.com/category/tower.png', text: 'Tower' },
            ],
            categories: [
                {
                    name: 'RivoBIT Originals',
                    games: [
                        { game_name: 'Rivo Jet', banner: 'https://banner.rivobit.com/originals/5.png', href: '/crash' },
                        { game_name: 'Rivo Blackjack', banner: 'https://banner.rivobit.com/originals/6.png', href: '/blackjack' },
                        { game_name: 'Rivo Roll', banner: 'https://banner.rivobit.com/originals/7.png', href: '/roll' },
                        { game_name: 'Rivo Mines', banner: 'https://banner.rivobit.com/originals/8.png', href: '/mines' },
                        { game_name: 'Rivo Tower', banner: 'https://banner.rivobit.com/originals/9.png', href: '/towers' },
                        { game_name: 'Rivo Duels', banner: 'https://banner.rivobit.com/originals/10.png', href: '/duels' },
                        { game_name: 'Rivo Nft', banner: 'https://banner.rivobit.com/originals/11.png', href: '/unbox' },
                        { game_name: 'Rivo Battles', banner: 'https://banner.rivobit.com/originals/12.png', href: '/battles' },
                    ],
                    route: 'originals',
                    icon: 'https://banner.rivobit.com/category2/1.png'
                },
                { name: 'Slots', games: [], route: 'slots', icon: 'https://banner.rivobit.com/category2/2.png' },
                { name: 'Live Casino', games: [], route: 'live-casino', icon: 'https://banner.rivobit.com/category2/3.png' },
                { name: 'Roulette', games: [], route: 'roulette', icon: 'https://banner.rivobit.com/category2/7.png' },
                
            ],
            error: null,
            loading: true,
        };
    },
    computed: {
        filteredButtons() {
            return this.buttons.filter(button => 
                button.text.toLowerCase().includes(this.searchQuery.toLowerCase())
            );
        },
        ...mapGetters(['authUser']), // Vuex store'dan authUser'ı alıyoruz
    },
    mounted() {
        this.fetchGames();
    },
    methods: {
    async fetchGames() {
        try {
            const responses = await Promise.all([
                this.fetchAllGamesByType(['slots']),
                this.fetchAllGamesByType(['tablegames']),
                this.fetchAllGamesByType(['roulette']),
                
            ]);

            this.categories[1].games = this.sortByFeatured(responses[0].data); // Slots
            this.categories[2].games = this.sortByFeatured(responses[1].data); // Live Casino
            this.categories[3].games = this.sortByFeatured(responses[2].data); // Roulette

        } catch (error) {
            console.error("Error fetching games:", error);
            this.error = error;
        } finally {
            this.loading = false;
        }
    },
    fetchAllGamesByType(types) {
        return axios.post('/drakon/games', {
            game_type: types,
            limit: 100 // Tüm oyunları getirmek için yüksek bir limit
        });
    },
    sortByFeatured(games) {
        return games.sort((a, b) => b.featured - a.featured);
    }
}
}

</script>



<style scoped>
    .home {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 45px 10px;
    }

    .home .home-banner {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        max-width: 1200px;
    }

    .home .home-buttons {
        width: 1250px;
        display: flex;
        justify-content: space-around;
        margin: 20px 0;
        flex-wrap: nowrap;
        overflow-x: auto;
    }

    .home .button-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100px;
        margin: 0 10px;
    }

    .home .button-item img {
        width: 45px;
        height: 45px;
        object-fit: cover;
    }

    .home .button-item span {
        margin-top: 10px;
        text-align: center;
        font-family: 'Rubik';
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
    }

    /* Kategoriler */
    .category-section {
        width: 100%;
        max-width: 1200px;
        margin: 20px 0;
    }

    .category-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        font-size: 12px;
        border-radius: 5px;
        color: white;
    }

    .category-title {
        display: flex;
        align-items: center;
    }

    .category-icon {
        width: 20px;
        height: 20px;
        margin-right: 8px;
    }

    .see-all-button {
        font-size: 14px;
        color: orange;
        text-decoration: none;
    }

    .games-list {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .game-item {
    display: flex;
    flex-direction: column; /* İsimleri resmin altına yerleştirir */
    align-items: center;
    flex: 0 0 auto;
    margin-right: 10px;
    text-align: center; /* Bu, metinlerin ortalanmasını sağlar */
}

.game-item a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none; /* Linkin altını çizmez */
    color: inherit; /* Metin rengini devralır */
}

.game-banner {
    width: 120px;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
}

.game-name {
    margin-top: 10px;
    font-family: 'Rubik';
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
}

    @media only screen and (max-width: 1600px) {
        .home .home-banner,
        .home .home-featured,
        .home .home-buttons,
        .home .home-games {
            width: 100%;
        }
        .home .home-buttons {
            flex-wrap: nowrap;
        }
    }

    @media only screen and (max-width: 950px) {
        .home {
            padding: 25px 10px 443px 10px;
        }

        .home .home-buttons {
            flex-wrap: nowrap;
        }

        .search-container {
            padding: 0 10px;
        }

        .search-box {
            max-width: 100%;
        }

        .home .button-item {
            width: 90px;
            margin: 0 5px;
        }
    }
</style>

