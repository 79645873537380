<template>
    <div class="home">
        <div class="home-banner">
            <HomeBannerUser />
        </div>

        <div class="home-buttons">
            <div class="button-item" v-for="(button, index) in categorybutton" :key="index">
                <img :src="button.imgSrc" alt="Button Image">
                <span>{{ button.text }}</span>
            </div>
        </div>
        
        <!-- Arama Alanı -->
        <div class="search-container">
            <div class="search-box">
                <img src="https://banner.rivobit.com/category/search.png" alt="Search Icon" class="search-icon" />
                <input v-model="searchQuery" type="text" placeholder="Search Games..." @focus="showSearchResults = true" @blur="hideSearchResults" />
                <span v-if="searchQuery" class="clear-icon" @click="clearSearch">×</span>
            </div>

            <!-- Arama Sonuçları -->
            <div v-if="showSearchResults && filteredGames.length > 0" class="search-results">
                <div v-for="(game, idx) in filteredGames" :key="idx" class="search-result-item">
                    <img :src="game.banner" alt="Game Banner" class="search-result-image">
                    <span class="search-result-name">{{ game.game_name }}</span>
                </div>
            </div>
        </div>

        <!-- Filtreleme Butonu -->
        <div class="filter-container">
            <button class="filter-button" @click="toggleFilterMenu">
                <img src="https://banner.rivobit.com/icons/filter.png" alt="Filter Icon" class="filter-icon">
                Providers
            </button>
            <div v-if="showFilterMenu" class="filter-menu">
                <ul>
                    <li v-for="(filter, index) in categoryFilters" :key="index" @click="applyFilter(filter.value)" class="filter-item">
                        <span class="checkbox" :class="{ checked: selectedFilter === filter.value }"></span>
                        <span class="filter-label">{{ filter.label }}&nbsp;&nbsp;{{ filter.count }}</span>
                    </li>
                </ul>
            </div>
        </div>

        <!-- Oyunlar -->
        <div class="game-category">
            <div class="games-list">
                <div v-for="(game, idx) in visibleGames" :key="idx" class="game-item">
  <router-link :to="{ 
      name: 'CasinoPlay', 
      query: { 
          game_code: game.game_code, 
          provider_code: game.provider_code, 
          user_id: authUser.user._id 
      } 
    }">
    <img :src="game.banner" alt="Game Banner" class="game-banner">
    <span class="game-name">{{ game.game_name }}</span>
  </router-link>
</div>

  
            </div>
            <button v-if="visibleGames.length < games.length" @click="loadMoreGames" class="load-more-button">
                Show 24 More
            </button>
        </div>

        <HomeDepositMethods />
        <Bets />
    </div>
</template>

<script>
import Bets from '@/components/bets/Bets';
import HomeBannerUser from '@/components/home/HomeBannerUser';
import HomeDepositMethods from '@/components/home/HomeDepositMethods';
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
    name: 'GameCategory',
    metaInfo: {
        title: 'RivoBIT: The Innovative Crypto Gambling Site'
    },
    components: {
        Bets,
        HomeBannerUser,
        HomeDepositMethods
        
    },
    data() {
        return {
            games: [],
            filteredGames: [], // Filtrelenmiş oyunlar
            visibleGames: [], // Başlangıçta gösterilecek oyunlar
            gamesToShow: 24, // Her seferinde gösterilecek oyun sayısı
            searchQuery: '', // Arama alanı için data
            selectedFilter: '', // Seçili filtreyi tutar
            showSearchResults: false, // Arama sonuçlarını göster/gizle
            buttons: [
                { imgSrc: 'https://banner.rivobit.com/category/hot.png', text: 'Hot' },
                { imgSrc: 'https://banner.rivobit.com/category/case.png', text: 'Case' },
                { imgSrc: 'https://banner.rivobit.com/category/bj.png', text: 'BJ' },
                { imgSrc: 'https://banner.rivobit.com/category/dice.png', text: 'Dice' },
                { imgSrc: 'https://banner.rivobit.com/category/crash.png', text: 'Crash' },
                { imgSrc: 'https://banner.rivobit.com/category/mine.png', text: 'Mine' },
                { imgSrc: 'https://banner.rivobit.com/category/btl.png', text: 'Battle' },
                { imgSrc: 'https://banner.rivobit.com/category/roll.png', text: 'Roll' },
                { imgSrc: 'https://banner.rivobit.com/category/rlt.png', text: 'Roulette' },
                { imgSrc: 'https://banner.rivobit.com/category/tower.png', text: 'Tower' },
            ],
            showFilterMenu: false, // Filtre menüsünü gösterip gizlemek için
            categoryName: this.$route.params.category || 'Games', // URL'den kategori adını al
        };
    },
    computed: {
        ...mapGetters(['authUser']), // Vuex store'dan authUser'ı alıyoruz
        categorybutton() {
            return this.buttons.filter(button => 
                button.text.toLowerCase().includes(this.searchQuery.toLowerCase())
            );
        },
    
        categoryFilters() {
            if (this.categoryName.toLowerCase() === 'slots') {
                return [
                    { label: 'Pragmatic', value: 'pragmatic', count: this.countGames('pragmatic') },
                    { label: 'PgSOFT', value: 'pgsoft', count: this.countGames('pgsoft') },
                    { label: 'EvoPlay', value: 'EVOPLAY', count: this.countGames('EVOPLAY') },
                    { label: 'Habanero', value: 'HABANERO', count: this.countGames('HABANERO') },
                    { label: 'Booongo', value: 'BOOONGO', count: this.countGames('BOOONGO') },
                    { label: 'PlaySon', value: 'PLAYSON', count: this.countGames('PLAYSON') },
                    { label: 'CQ9', value: 'CQ9', count: this.countGames('CQ9') },
                    { label: 'TopTrend', value: 'TOPTREND', count: this.countGames('TOPTREND') },
                    { label: 'DreamTech', value: 'DREAMTECH', count: this.countGames('DREAMTECH') },
                    { label: 'ReelKingdoom', value: 'REELKINGDOM', count: this.countGames('REELKINGDOM') },
                   
                    
                    
                ];
            } else if (this.categoryName.toLowerCase() === 'live-casino') {
                return [
                    { label: 'Evolution', value: 'EVOLUTION', count: this.countGames('EVOLUTION') },
                    { label: 'Pragmatic Live', value: 'PRAGMATICLIVE', count: this.countGames('PRAGMATICLIVE') },
                    { label: 'Ezugi', value: 'EZUGI', count: this.countGames('EZUGI') }
                    
                ];
            }
            return [];
        }
    },
    watch: {
        searchQuery(newQuery) {
            this.filteredGames = this.games.filter(game =>
                game.game_name.toLowerCase().includes(newQuery.toLowerCase())
            );
        }
    },
    mounted() {
        this.fetchGames();
    },
    methods: {
    async fetchGames() {
        try {
            const categoryQueries = {
                'slots': ['slots'],
                'live-casino': ['tablegames'],
                'roulette': ['roulette']
               
            };

            const queryTypes = categoryQueries[this.categoryName.toLowerCase()] || [];

            if (queryTypes.length > 0) {
                const response = await axios.get('/drakon/games', {
                    params: {
                        game_type: queryTypes,
                        limit: 100 // Daha yüksek bir limit belirleyin veya limiti kaldırın
                    }
                });

                // Oyunları featured alanına göre sıralama
                this.games = this.sortByFeatured(response.data);
                this.filteredGames = this.games; // İlk olarak tüm oyunları göster
                this.visibleGames = this.filteredGames.slice(0, this.gamesToShow); // Başlangıçta ilk 24 oyunu göster
            } else {
                console.error("Invalid category name:", this.categoryName);
            }
        } catch (error) {
            console.error("Error fetching games:", error);
        }
    },
    clearSearch() {
            this.searchQuery = ''; // Arama kutusunu temizle
            this.filteredGames = []; // Filtrelenmiş oyunları temizle
            this.showSearchResults = false; // Arama sonuçlarını gizle
        },
    sortByFeatured(games) {
        return games.sort((a, b) => b.featured - a.featured);
    },
    loadMoreGames() {
        const nextSetOfGames = this.filteredGames.slice(this.visibleGames.length, this.visibleGames.length + this.gamesToShow);
        this.visibleGames = this.visibleGames.concat(nextSetOfGames);
    },
    applyFilter(provider) {
        this.selectedFilter = provider; // Seçili filtreyi güncelle
        this.filteredGames = this.games.filter(game => game.provider_game === provider);
        this.visibleGames = this.filteredGames.slice(0, this.gamesToShow);
        this.showFilterMenu = false; // Filtre seçildikten sonra menüyü kapat
    },
    toggleFilterMenu() {
        this.showFilterMenu = !this.showFilterMenu;
    },
    countGames(provider) {
        return this.games.filter(game => game.provider_game === provider).length;
    },
    hideSearchResults() {
        setTimeout(() => {
            this.showSearchResults = false;
        }, 500); // Kullanıcı tıklamasını yakalamak için kısa bir gecikme
    }
}
}
</script>

<style scoped>
    .home {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 45px 10px;
    }

    .home .home-banner {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        max-width: 1200px;
    }

    .home .home-buttons {
        width: 1250px;
        display: flex;
        justify-content: space-around;
        margin: 20px 0;
        flex-wrap: nowrap;
        overflow-x: auto;
    }

    .home .button-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100px;
        margin: 0 10px;
    }

    .home .button-item img {
        width: 45px;
        height: 45px;
        object-fit: cover;
    }

    .home .button-item span {
        margin-top: 10px;
        text-align: center;
        font-family: 'Rubik';
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
    }

    /* Arama Alanı Stil */
    .search-container {
        margin-bottom: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 0 20px;
        position: relative;
    }

    .search-box {
    position: relative;
    width: 100%;
    max-width: 1200px;
}

.search-box .clear-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    font-size: 18px;
    color: #6e95b6;
    cursor: pointer;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.search-box .clear-icon:hover {
    color: #3d88c7;
}

    .search-box input {
        width: 100%;
        padding: 10px 10px 10px 40px;
        border:1px solid #3d88c7;
        border-radius: 5px;
        font-size: 16px;
        background: linear-gradient(255deg, rgba(59, 126, 183, 0.5) -70%, rgba(20, 80, 129, 0.5) 90%);
        color: #6e95b6;
    }

    .search-box .search-icon {
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
    }

    .search-results {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: #062843;
        border-radius: 5px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        z-index: 10;
        max-height: 300px;
        overflow-y: auto;
    }

    .search-result-item {
        display: flex;
        align-items: center;
        padding: 10px;
        border-bottom: 1px solid #3d88c7;
        cursor: pointer;
    }

    .search-result-item:hover {
        background-color: #011627;
    }

    .search-result-image {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 5px;
        margin-right: 10px;
    }

    .search-result-name {
        font-size: 14px;
        color: #ffffff;
    }

    .filter-container {
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-end; /* Butonu en sağa hizalar */
        width: 100%; /* Tüm genişliği kaplar */
        max-width: 1200px; /* İçeriği hizalı tutmak için maksimum genişlik belirler */
        position: relative; /* Menüyü container içinde tutmak için relative pozisyon */
    }

    .filter-button {
        display: flex;
        align-items: center;
        padding: 10px 20px;
        background-color: rgb(20 61 94 / 95%);
        color: #ffffff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }

    .filter-icon {
        margin-right: 8px;
        width: 16px;
        height: 16px;
    }

    .filter-menu {
        position: absolute;
        top: 100%; /* Butonun hemen altında açılır */
        right: 0; /* Menü sağa hizalanır */
        background-color: rgb(20 61 94 / 95%);
        border-radius: 5px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        padding: 10px;
        z-index: 10;
        color: white;
        min-width: 150px; /* Menü genişliğini belirler */
    }

    .filter-menu ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .filter-menu li {
        display: flex;
        align-items: center;
        padding: 8px 12px;
        cursor: pointer;
    }

    .filter-menu li:hover {
        background-color: #f0f0f0;
    }

    .checkbox {
        width: 16px;
        height: 16px;
        border: 1px solid #3d88c7;
        border-radius: 3px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .checkbox.checked {
        background-color: #3d88c7;
    }

    .checkbox.checked::before {
        content: "✓";
        color: white;
        font-size: 12px;
    }

    .filter-label {
        font-size: 14px;
        color: #fff;
    }

    .games-list {
        display: flex;
        flex-wrap: wrap; /* Oyunları birden fazla satırda göster */
        justify-content: center;
        max-width: 1200px;
    }

    .game-item {
        flex: 0 1 calc(12.5% - 10px); /* 8 sütunlu grid */
        margin: 5px;
        display: flex;
        flex-direction: column; /* İsimleri ve görselleri dikey hizalar */
        align-items: center; /* Ortaya hizalar */
        
    }

    .game-banner {
        width: 100%;
        height: 150px;
        object-fit: cover;
        border-radius: 10px;
        
    }

    

    .game-name {
        margin-top: 10px;
        text-align: center;
        font-family: 'Rubik';
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
    }

    .load-more-button {
        display: block;
        margin: 20px auto;
        padding: 10px 20px;
        background-color: #3d88c7;
        color: #ffffff;
        border: none;
        border-radius: 5px;
        font-size: 16px;
        cursor: pointer;
    }

    .load-more-button:hover {
        background-color: #2a6ca3;
    }

    @media only screen and (max-width: 600px) {
        .game-item {
            flex: 0 1 calc(33.333% - 10px); /* Mobilde 3 sütun */
        }
    }
    @media only screen and (max-width: 1600px) {
        .home .home-banner,
        .home .home-featured,
        .home .home-buttons,
        .home .home-games {
            width: 100%;
        }
        .home .home-buttons {
            flex-wrap: nowrap;
        }
    }

    @media only screen and (max-width: 950px) {
        .home {
            padding: 25px 10px 443px 10px;
        }

        .home .home-buttons {
            flex-wrap: nowrap;
        }

        .search-container {
            padding: 0 10px;
        }

        .search-box {
            max-width: 100%;
        }

        .home .button-item {
            width: 90px;
            margin: 0 5px;
        }
    }
</style>
