import { render, staticRenderFns } from "./BattlesCreateFilters.vue?vue&type=template&id=5dfa54aa&scoped=true"
import script from "./BattlesCreateFilters.vue?vue&type=script&lang=js"
export * from "./BattlesCreateFilters.vue?vue&type=script&lang=js"
import style0 from "./BattlesCreateFilters.vue?vue&type=style&index=0&id=5dfa54aa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dfa54aa",
  null
  
)

export default component.exports