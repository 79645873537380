<template>
  <div class="bottom-navigation">
    <div 
      v-for="(item, index) in items" 
      :key="item.text" 
      :class="['nav-item', { 'middle-button': index === 3, 'selected': selectedItem === item.text }]" 
      @click="navigate(item)"
    >
      <img 
        :src="getIcon(item)" 
        alt="icon" 
        :class="['nav-icon', { 'wallet-icon': item.text === 'Wallet' }]" 
      />
      <span :class="['nav-text', { 'selected-text': selectedItem === item.text }]">{{ item.text }}</span>
    </div>
  </div>
</template>

  <script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      items: [
        { text: 'Menu', route: '/', icon: 'https://banner.rivobit.com/bottom/1.png', selectedIcon: 'https://banner.rivobit.com/bottom/1-1.png' }, 
        { text: 'Home', route: '/', icon: 'https://banner.rivobit.com/bottom/2.png', selectedIcon: 'https://banner.rivobit.com/bottom/2-2.png' },
        { text: 'Slot', route: '/slot', icon: 'https://banner.rivobit.com/bottom/3.png', selectedIcon: 'https://banner.rivobit.com/bottom/3-3.png' },
        { text: 'Wallet', route: '/deposit', icon: 'https://banner.rivobit.com/bottom/wallet.svg', selectedIcon: 'https://banner.rivobit.com/bottom/wallet.svg' },
        { text: 'Sports', route: '/sports', icon: 'https://banner.rivobit.com/bottom/5.png', selectedIcon: 'https://banner.rivobit.com/bottom/5-5.png' },
        { text: 'Vault', route: '/rewards', icon: 'https://banner.rivobit.com/bottom/6.png', selectedIcon: 'https://banner.rivobit.com/bottom/6-6.png' },
        { text: 'Chat', route: '/chat', icon: 'https://banner.rivobit.com/bottom/7.png', selectedIcon: 'https://banner.rivobit.com/bottom/7-7.png' }
      ],
      selectedItem: 'Home' // Varsayılan olarak ilk buton seçili
    };
  },
  methods: {
    ...mapActions([
      'generalSetSidebarMobile',
      'modalsSetShow'
    ]),
    navigate(item) {
      if (this.selectedItem === item.text) {
        // İkinci kez tıklama durumunda
        if (item.text === 'Chat' || item.text === 'Menu') {
          this.generalSetSidebarMobile(null); // Sidebar'ı kapat
        } else if (item.text === 'Wallet' || item.text === 'Vault') {
          this.modalsSetShow(null); // Modal'ı kapat
        }
        this.selectedItem = null; // Seçili öğeyi temizle
      } else {
        // İlk kez tıklama veya farklı bir öğeye tıklama durumu
        this.selectedItem = item.text; // Seçili öğeyi güncelle
        if (item.text === 'Chat') {
          this.generalSetSidebarMobile('Chat');
        } else if (item.text === 'Menu') {
          this.generalSetSidebarMobile('Navbar');
        } else if (item.text === 'Wallet') {
          this.modalsSetShow('Cashier');
        } else if (item.text === 'Vault') {
          this.navbarVaultButton();
        } else {
          this.$router.push(item.route);
        }
      }
    },
    getIcon(item) {
      return this.selectedItem === item.text ? item.selectedIcon : item.icon;
    },
    navbarVaultButton() {
      this.modalsSetShow('Vault');
      this.generalSetSidebarMobile(null);
    }
  }
};
</script>


  
  <style>
  .bottom-navigation {
    border-width: 1px 0 0;
    display: flex;
    height: 58px;
    justify-content: space-between;
    padding: 10px 12px;
    align-items: center;
    transition: border-radius .1s ease-in-out;
    z-index: 222;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: rgb(9 40 65 / 95%);
    font-family: 'Rubik';
    color: #6a88a1;
    border-radius: 12px 12px 0 0;
  }
  
  .middle-button {
  position: relative;
  top: -10px; /* Ortadaki düğmeyi yukarı kaldır */
  background: linear-gradient(255deg, #ff9400 -25%, #aa5c00 100%);
  color: #000;
  width: 65px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  transition: top .1s ease-in-out;
  clip-path: polygon(50% 0%, 90% 25%, 90% 75%, 50% 100%, 10% 75%, 10% 25%); /* Altıgen şekil */
}

  
  .bottom-navigation::before {
    content: "";
    position: absolute;
    top: -30px; /* Kavis yüksekliği */
    left: 50%;
    transform: translateX(-50%);
    width: 60px; /* Kavis genişliği */
    height: 60px;
    
    border-radius: 50%;
    z-index: -1; /* Navigasyon çubuğunun arkasında kalması için */
  }
  
  .nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 5px;
    position: relative;
  }
  
  .nav-icon {
    width: 20px;
    height: 20px;
    margin-bottom: 5px;
  }

  .wallet-icon {
  width: 31px;
  height: 31px;
}
  
  .nav-text {
    font-size: 12px;
  }
  
  .selected-text {
    color: #ff9400; /* Seçili butonun altındaki yazının rengi */
  }
  
  .selected::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 4px;
    background-color: #ff9400;
    border-radius: 5px;
    box-shadow: 0 0 10px #ff9400, 0 0 20px #ff9400, 0 0 30px #ff9400, 0 0 40px #ff9400;
  }
  
  @media (min-width: 768px) {
    .bottom-navigation {
      display: none;
    }
  }
  </style>
  