<!-- src/components/PaymentSuccess.vue -->

<template>
    <div class="payment-success">
      <h1>Ödeme Başarılı!</h1>
      <p>Ödemeniz başarıyla tamamlandı.</p>
      <p>İşlem detaylarınızı kontrol edebilirsiniz.</p>
      <router-link to="/">Ana Sayfaya Dön</router-link>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        // Burada ödeme ile ilgili bilgileri gösterebilirsiniz
      };
    },
    mounted() {
      // Gerekli olursa burada ödeme ile ilgili detayları backend'den alabilirsiniz
    },
  };
  </script>
  
  <style scoped>
  .payment-success {
    text-align: center;
    margin-top: 50px;
  }
  
  .payment-success h1 {
    color: #28a745;
  }
  
  .payment-success p {
    font-size: 18px;
    margin-top: 20px;
  }
  
  .payment-success a {
    margin-top: 30px;
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .payment-success a:hover {
    background-color: #0056b3;
  }
  </style>
  